.root {
    width: 100%;

    background-image: url(https://lirp.cdn-website.com/fdd2293f/dms3rep/multi/opt/footer-background-img-1920w.jpg);
    background-size: cover;

    /* background-image: url("/img/footer-background.webp"); */
    /* background-color: var(--spatech-green); */
    min-height: 90px;
}

.wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 18px 20px;
}

.logo {
    width: 176px;
}

.text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: var(--whitebase);
    padding: 0;
    margin: 0;
    width: 100%;
}

.social {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
}

.social a {
    text-decoration: none;
    color: var(--spatech-green);
}

.campusContainer {
    padding-bottom: 5px;
}

.campuses {
    color: var(--black-700);
    text-align: center;
}

.campusContainer .code {
    font-size: 14px;
}

.campusContainer .address,
.campusContainer .phone {
    font-size: 12px;
}

.campuses h5 {
    margin: 0 0 5px 0;
    font-size: 16px;
}
/* --tablet-widths */
@media (min-width: 660px) {
}

/* --desktop-widths */
@media (min-width: 1024px) {
    .wrapper {
        padding-top: 24px;
        padding-bottom: 22px;
    }

    .text {
        font-size: 14px;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
    .wrapper {
        margin: 0 auto;
        display: grid;
        grid-template-columns: 6fr;
        padding: 20px 40px;
        place-items: center;
    }

    .logo {
        max-width: 200px;
        height: auto;
    }
}