.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
    width: 100%;
    background-color: var(--whitebase);
    padding-bottom: 40px;
}

.h2 {
    margin: 22px 0;
    padding: 0;
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.67;
    letter-spacing: normal;
    color: var(--black-700);
    max-width: 466px;
    display: inline-block;
}

.text {
    margin: 0 14px;
    max-width: 466px;
    display: inline-block;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-700);
    margin-bottom: 10px;
}

.formArea {
    margin-top: 14px;

    width: 100%;
    max-width: 289px;
}

.formArea > label {
    margin-bottom: 11px;
}

.notesText {
    font-family: inherit;
    font-size: 9px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--black-700);
    margin: 0;
    margin-top: 6px;
}

.textUnderForm {
    max-width: 466px;
    font-family: inherit;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--black-700);
    margin: 0 14px;
    margin-top: 14px;
    padding: 0;
}

.button {
    max-width: 394px;
    margin-top: 20px;
}

.header {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    position: relative;
}

.link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 16px;
    border: none;
    border-radius: 4px;
    width: 100%;
    height: 32px;
    background-color: var(--tertiary-500);
    color: var(--whitebase);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-transform: uppercase;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    text-decoration: none;
    margin-bottom: 0px;
    margin-top: 20px;
}

.link:active {
    background-color: var(--tertiary-600);
}

.link:disabled {
    background-color: var(--black-100);
    cursor: auto;
}

.link:focus {
    box-shadow: 0 0 0 2px var(--primary-400-base);
}

.checkBoxWrapper {
    margin: 13px 0;
}

.fontSize14 {
    font-size: 14px;
    font-weight: 500;
}

/* --tablet-widths */
@media (min-width: 660px) {
}

/* --desktop-widths */
@media (min-width: 1024px) {
    .root {
        background-color: var(--whitebase-70);
        border-radius: 7px;
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: relative;
    }

    .link {
        margin-top: 0;
        margin-bottom: 14px;
        width: 122px;
    }

    .wrapper {
        margin-top: 28px;
        margin-bottom: 47px;
        background-color: transparent;
        border-radius: 7px;
    }

    .h2 {
        margin-top: 33px;
        margin-bottom: 14px;
        margin-left: 192px;
        margin-right: 70px;
        font-size: 36px;
        line-height: normal;
    }

    .formArea {
        padding-bottom: 14px;
    }

    .textUnderForm {
        margin: 0;
    }
}

/* --mobile-widths-only */
@media (max-width: 659px) {
}
